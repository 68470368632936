import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, CircularProgress, FormHelperText } from '@mui/material';
import Logger from '@util/Logger';
import { CellKBSpecies } from '@models/CellKB';

const logger = Logger.make('CellKBSpeciesSelector');

type CellKBSpeciesSelectorProps = {
    value: number;
    onChange: (newValue: number) => void;
    disabled?: boolean;
};

const CellKBSpeciesSelector = ({ value, onChange, disabled = false }: CellKBSpeciesSelectorProps) => {
    const [species, setSpecies] = useState<CellKBSpecies[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    // Helper function for retry logic with exponential backoff
    const withRetry = async <T,>(fn: () => Promise<T>, retries = 3, initialDelay = 1000): Promise<T> => {
        let lastError: Error | null = null;

        for (let attempt = 1; attempt <= retries; attempt++) {
            try {
                return await fn();
            } catch (error: any) {
                lastError = error;
                logger.warn(`Attempt ${attempt} failed:`, error.message);

                if (attempt === retries) {
                    throw error;
                }

                // Wait before retrying with exponential backoff
                const delay = initialDelay * Math.pow(2, attempt - 1);
                logger.info(`Retrying in ${delay}ms...`);
                await new Promise((resolve) => setTimeout(resolve, delay));
            }
        }

        // This should never happen, but TypeScript requires a return
        throw lastError || new Error('Unknown error during retry');
    };

    useEffect(() => {
        const fetchSpecies = async () => {
            setLoading(true);
            setError(null);

            try {
                // Define the API call function for retry
                const makeApiCall = async () => {
                    const response = await fetch('/api/cellkb/species', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({}),
                    });

                    if (!response.ok) {
                        throw new Error(`Error: ${response.status} ${response.statusText}`);
                    }

                    return await response.json();
                };

                // Call the API with retry logic
                const data = await withRetry(makeApiCall);

                if (data.response && data.response.status_code === 'SUCCESS') {
                    setSpecies(data.response.result || []);
                } else {
                    setError('Failed to fetch species');
                }
            } catch (error: any) {
                logger.error('Error fetching species:', error);
                setError(`Failed to fetch species: ${error.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchSpecies();
    }, []);

    return (
        <FormControl error={!!error} disabled={disabled || loading} size="small" style={{ maxWidth: '500px' }}>
            <Select
                labelId="species-select-label"
                value={value}
                onChange={(e) => onChange(e.target.value as number)}
                startAdornment={loading ? <CircularProgress color="inherit" size={16} /> : null}
                size="small"
            >
                {species.map((option) => (
                    <MenuItem key={option.taxon_no} value={option.taxon_no}>
                        {option.common_name} ({option.taxon_name})
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText style={{ fontSize: '0.7rem' }}>
                {error ||
                    'CellKb currently supports a limited number of organisms. If the exact species from your experiment isn&apos;t yet supported, Pluto has defaulted the organism to the closest available species.'}
            </FormHelperText>
        </FormControl>
    );
};

export default CellKBSpeciesSelector;
