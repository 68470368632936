import { ApiError } from '@services/ApiError';
import { deepEqual, getChangedValues } from '@util/ObjectUtil';
import { FieldArray, Formik, FormikHelpers } from 'formik';
import { getFormSetup } from '@components/experiments/annotations/AnnotationFormUtil';
import { isBlank, isWhite } from '@util/StringUtil';
import { ScrollableSidebarContent, ScrollableSidebarFooter } from '@components/experiments/ScrollableSidebarContent';
import { useExperimentDetailViewContext } from '@contexts/ExperimentDetailViewContext';
import AggregateFormErrorAlert from '@components/experiments/AggregateFormErrorAlert';
import AnalysisFormSubmitButton from '@components/experiments/analyses/AnalysisFormSubmitButton';
import FormikListener from '@components/forms/FormikListener';
import LoadingMessage from '@components/LoadingMessage';
import Logger from '@util/Logger';
import React, { ChangeEvent, useCallback, useEffect, useState, useRef } from 'react';
import { AnnotationFormValues, AnnotationValues } from './AnnotationFormTypes';
import { DESCRIPTION_CHARACTER_LIMIT } from '@models/Experiment';
import TextAreaField from '@components/forms/TextAreaField';
import { Accordion, AccordionDetails, AccordionSummary, Button, createTheme } from '@mui/material';
import { LegendSVG } from '@components/plots/PlotLegendView';
import cn from 'classnames';
import { useExperimentAnnotationContext } from '@contexts/ExperimentAnnotationContext';
import TextInputField from '@components/forms/TextInputField';
import CustomLegendColorField, { CustomLegendColorItem } from '../plotDisplay/groups/CustomLegendColorField';
import ConfirmSaveAnnotationDialog from './ConfirmSaveAnnotationDialog';
import { CSSTransition } from 'react-transition-group';
import { Annotation } from '@models/Annotation';
import { useDebounce } from 'react-use';
import useOrganizationPermissions from '@hooks/useOrganizationPermissions';
import Experiment from '@models/Experiment';
import { InformationCircleIcon, LightBulbIcon } from '@heroicons/react/outline';
import { Tooltip } from '@mui/material';
import { GenericCellData } from '@/src/models/ExperimentData';
import { ResultData } from '@/src/models/PreprocessStep';
import ClusterIdentificationModal from './ClusterIdentificationModal';

const theme = createTheme();

const styles = {
    accordion: {
        boxShadow: 'none',
        borderRadius: '10px !important',
        '&.Mui-expanded': { margin: 0, border: `2px solid ${theme.palette.primary.main}` },
        '&:not(expanded)': { border: `0.5px solid ${theme.palette.primary.light}` },
        '&:last-child': { marginBottom: 0 },
    },
    accordionSummary: {
        padding: theme.spacing(0, 3),
        [theme.breakpoints.up('lg')]: { padding: theme.spacing(0, 6) },
        '&.Mui-expanded': {
            height: 0,
            minHeight: '0 !important',
            overflow: 'hidden',
            margin: 0,
            transition: 'min-height 0.3s',
        },
    },
    accordionDetails: { padding: theme.spacing(3), [theme.breakpoints.up('lg')]: { padding: theme.spacing(3, 6) } },
};

const logger = Logger.make('ExperimentPreprocessForm');

const CellTypeDisplay = ({
    cellTypes,
}: {
    cellTypes: { tissue_type: string; confidence: string; reasons: string }[];
}) => (
    <div>
        {cellTypes.map((cellTypeObj, index) => (
            <div key={index} className="flex items-center mb-2">
                <span className="text-md font-semibold">
                    {cellTypeObj.tissue_type} ({cellTypeObj.confidence})
                </span>
                <Tooltip title={cellTypeObj.reasons} arrow>
                    <span className="ml-2 cursor-pointer">
                        <InformationCircleIcon className="h-5 w-5 text-gray-500" />
                    </span>
                </Tooltip>
            </div>
        ))}
    </div>
);

export const fetchCellTypes = async (
    clusterNumber: number,
    plotData: ResultData,
    experiment: Experiment,
): Promise<any> => {
    try {
        const plotDataSnippet = {
            key: { G: 'Gene_Symbol', LFC: 'Log2_Fold_Change', AdjP: 'Adj_P_Value' },
            data: {
                hdrs: ['G', 'LFC', 'AdjP'],
                items: (plotData.items as GenericCellData[])
                    .filter(
                        (item: GenericCellData) =>
                            item.Log2_Fold_Change !== undefined && item.Log2_Fold_Change !== null,
                    )
                    .sort(
                        (a: GenericCellData, b: GenericCellData) =>
                            ((b?.Log2_Fold_Change as number) || 0) - ((a.Log2_Fold_Change as number) || 0),
                    )
                    .slice(0, 50) // Use top 50 marker genes for better cell type identification
                    .map((item: GenericCellData) => ({
                        G: item.Gene_Symbol,
                        LFC: parseFloat((item.Log2_Fold_Change as number).toFixed(2)),
                        AdjP: item.Adj_P_Value,
                    })),
            },
        };

        const experiment_info = experiment;
        const prompt = `Given the following plot data, provide potential tissue types for this cluster. Output JSON with {tissue_type, confidence, reasons} under tissue_types. Plot Data: ${JSON.stringify(
            plotDataSnippet,
        )}. Experiment info: Name: ${experiment_info.name}, organism: ${experiment_info.organism}. Cell number: ${clusterNumber}. Marker genes: ${plotDataSnippet}.`;

        const response = await fetch('/api/vertex/vertex-gemini', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ prompt }),
        });

        if (!response.ok) {
            throw new Error('Failed to fetch tissue types');
        }

        return await response.json();
    } catch (error) {
        logger.error('Error fetching tissue types from the API:', error);
        return [{ tissue_type: 'unknown', confidence: 'N/A', reasons: 'No suggestions available' }];
    }
};

const AnnotationForm = ({
    openAISidebar,
    experiment,
}: {
    openAISidebar: (arg: number) => void;
    experiment: Experiment;
}) => {
    const { setCurrentChanges, currentChanges } = useExperimentDetailViewContext();
    const { features } = useOrganizationPermissions();
    const {
        selectedAnnotation,
        selectedAnnotationSet: annotationSet,
        setSelectedAnnotation,
        updateAnnotationSet,
        updateAnnotationSetError,
        updateAnnotationSetLoading,
        editAnnotationFormRef: formRef,
        plotData,
    } = useExperimentAnnotationContext();

    const [expanded, setExpanded] = useState<string | null>(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
    const [confirmSubmitOpen, setConfirmSubmitOpen] = useState<boolean>(false);
    const [identificationModalOpen, setIdentificationModalOpen] = useState<boolean>(false);
    const [cellTypes, setCellTypes] = useState<{ [key: string]: any }>({});
    const [processingCluster, setProcessingCluster] = useState<string | null>(null);
    const [loadingAIAnnotations, setLoadingAIAnnotations] = useState<{ [key: string]: boolean }>({});
    const requestRef = useRef<number | null>(null);

    const getAiResponse = useCallback(async () => {
        if (selectedAnnotation && plotData) {
            const requestId = Date.now();
            requestRef.current = requestId;

            setProcessingCluster(selectedAnnotation.uuid);
            setLoadingAIAnnotations((prev) => ({ ...prev, [selectedAnnotation.uuid]: true }));

            try {
                const cellTypesResponse = await fetchCellTypes(selectedAnnotation.number, plotData, experiment);
                const parsedResponse = cellTypesResponse.response?.[0]?.tissue_types || [];

                if (parsedResponse && Array.isArray(parsedResponse) && requestRef.current === requestId) {
                    setCellTypes((prev) => ({ ...prev, [selectedAnnotation.uuid]: parsedResponse }));
                } else if (requestRef.current !== requestId) {
                    logger.log('Request outdated, not updating the cell types.');
                }
            } catch (error) {
                logger.error('Error fetching AI annotations:', error);
                setCellTypes((prev) => ({
                    ...prev,
                    [selectedAnnotation.uuid]: 'No suggestions available for this cluster',
                }));
            } finally {
                setLoadingAIAnnotations((prev) => ({ ...prev, [selectedAnnotation.uuid]: false }));
                setProcessingCluster(null);
            }
        }
    }, [selectedAnnotation, plotData, experiment]);

    useEffect(() => {
        if (
            features?.experiment_features?.ai_assistant_enabled &&
            expanded &&
            Boolean(plotData) &&
            selectedAnnotation &&
            !cellTypes[selectedAnnotation.uuid]
        ) {
            getAiResponse();
        }
    }, [
        features?.experiment_features?.ai_assistant_enabled,
        expanded,
        Boolean(plotData),
        selectedAnnotation,
        cellTypes,
    ]);

    useDebounce(
        () => {
            if (formRef.current?.values && selectedAnnotation) {
                const updatedAnnotation = formRef.current?.values?.annotations.find(
                    (anno) => anno.uuid === selectedAnnotation?.uuid,
                );
                if (updatedAnnotation) {
                    setSelectedAnnotation((prev) =>
                        prev ? { ...prev, display_name: updatedAnnotation.display_name } : null,
                    );
                }
            }
        },
        500,
        [formRef.current?.values?.annotations],
    );

    const handleAccordionOpen = useCallback(
        (annotation: Annotation | AnnotationValues) => (_: any, isExpanded: boolean) => {
            setExpanded(isExpanded ? annotation.uuid : null);

            if (isExpanded) {
                setSelectedAnnotation(annotation);
            }
        },
        [getAiResponse],
    );

    /**
     * Get initial values for the analysis form
     * @type {{initialValues: AnnotationFormValues, schema: Yup.AnySchema} | null}
     */
    const setup = getFormSetup({ annotationSet });
    if (!setup) {
        logger.warn(`Cannot generate a setup object for annotation set "${annotationSet?.display_name}"`, {
            annotationSet,
        });
        return <ScrollableSidebarContent>This annotation set is not yet supported.</ScrollableSidebarContent>;
    }

    const handleSubmit = async (values: AnnotationFormValues, helpers: FormikHelpers<AnnotationFormValues>) => {
        helpers.setStatus(null);
        try {
            const updatedAnnotationSet = await updateAnnotationSet(values);

            if (updatedAnnotationSet && !updateAnnotationSetLoading && !updateAnnotationSetError) {
                setShowSuccessMessage(true);
                const updatedSelectedAnnotation = updatedAnnotationSet.clusters.find(
                    (cluster) => cluster.uuid === selectedAnnotation?.uuid,
                );
                setSelectedAnnotation(updatedSelectedAnnotation as Annotation);
                setTimeout(() => setShowSuccessMessage(false), 3000);
            }
        } catch (error) {
            logger.error('ApiErrorMessage', ApiError.getMessage(error as Error));
            helpers.setStatus({ error: ApiError.getMessage(error as Error) });
        } finally {
            helpers.setSubmitting(false);
            setCurrentChanges(null);
        }
    };

    const handleOnChange = useCallback(
        (values: AnnotationFormValues) => {
            const isEqual = deepEqual(values, setup.initialValues);
            const changedValues = setup.initialValues ? getChangedValues(values, setup.initialValues) : {};

            if (!isEqual && !deepEqual(changedValues, currentChanges)) {
                setCurrentChanges(changedValues);
            } else if (currentChanges) {
                setTimeout(() => setCurrentChanges(null), 300);
            }
        },
        [currentChanges, setup.initialValues],
    );

    if (!annotationSet) {
        return <LoadingMessage message="Loading..." />;
    }

    const submitDisabled = updateAnnotationSetLoading;
    const aiEnabled = !!features?.experiment_features?.ai_assistant_enabled;

    return (
        <>
            <Formik
                initialValues={setup.initialValues}
                validationSchema={setup.schema}
                onSubmit={handleSubmit}
                enableReinitialize
                key={annotationSet?.uuid}
                innerRef={formRef}
            >
                {({ status, errors, touched, values }) => {
                    const errorMessages = Object.keys(errors)
                        .filter(
                            (name) =>
                                (touched as Record<string, boolean>)[name] &&
                                !isBlank((errors as Record<string, string>)[name]),
                        )
                        .map((name) => (errors as Record<string, string>)[name]);

                    return (
                        <>
                            <div className="px-8">
                                <p className="mb-4 mt-8">
                                    <span className="mr-1 font-semibold">Annotation set</span>{' '}
                                    {annotationSet?.display_name}
                                </p>
                                <div>
                                    <h4 className="flex flex-row text-lg tracking-tight text-default">
                                        <span className="mr-1 font-semibold text-dark">Clusters</span> (
                                        {annotationSet?.clusters?.length ?? 0})
                                    </h4>
                                    <p className="text-gray-400">
                                        Click on a cluster to edit its display characteristics and view marker genes.{' '}
                                        <a href="" target="_blank" rel="noreferrer">
                                            Learn more
                                        </a>
                                    </p>
                                </div>
                                <div className="mb-2 mt-4 flex justify-end">
                                    <Button
                                        color="primary"
                                        variant="text"
                                        onClick={() => {
                                            setExpanded(null);
                                            setTimeout(() => {
                                                setSelectedAnnotation(null);
                                            }, 300);
                                        }}
                                    >
                                        View All Clusters
                                    </Button>
                                </div>
                            </div>
                            <ScrollableSidebarContent data-cy="annotationSet-form" className="px-8">
                                <FieldArray name="annotations">
                                    {({ replace }) => (
                                        <>
                                            {values.annotations.map((annotation, index) => {
                                                const colorItems: CustomLegendColorItem[] = [
                                                    {
                                                        id: annotation.display_name ?? '',
                                                        label: 'Color',
                                                        themeColor: annotation.color ?? '',
                                                    },
                                                ];
                                                return (
                                                    <div className="mb-4" key={annotation.uuid}>
                                                        <Accordion
                                                            sx={styles.accordion}
                                                            expanded={expanded === annotation.uuid}
                                                            onChange={handleAccordionOpen(annotation)}
                                                        >
                                                            <AccordionSummary
                                                                expandIcon={null}
                                                                sx={styles.accordionSummary}
                                                            >
                                                                <div className="flex flex-1 items-center justify-between">
                                                                    <span className="text-md font-semibold tracking-tight">
                                                                        {annotation.display_name ||
                                                                            `Cluster ${annotation.number}`}
                                                                    </span>
                                                                    <LegendSVG
                                                                        radius={4}
                                                                        width={24}
                                                                        style={{
                                                                            fill: annotation.color,
                                                                            fillOpacity: 1,
                                                                            stroke: isWhite(annotation.color)
                                                                                ? 'rgb(209, 213, 219)'
                                                                                : annotation.color,
                                                                            strokeOpacity: 1,
                                                                        }}
                                                                        className={cn(
                                                                            'shrink-0 transition-all duration-500',
                                                                        )}
                                                                    />
                                                                </div>
                                                            </AccordionSummary>
                                                            <AccordionDetails sx={styles.accordionDetails}>
                                                                <div className="flex flex-1 flex-col">
                                                                    <TextInputField
                                                                        label="Label & color"
                                                                        subLabel={`(Cluster ${annotation.number})`}
                                                                        name="display_name"
                                                                        value={annotation.display_name}
                                                                        onChange={(
                                                                            e: ChangeEvent<HTMLInputElement>,
                                                                        ) => {
                                                                            replace(index, {
                                                                                ...annotation,
                                                                                display_name: e.target.value,
                                                                            });
                                                                        }}
                                                                        componentRight={
                                                                            <div className="ml-3 mr-1">
                                                                                <CustomLegendColorField
                                                                                    hideLabel
                                                                                    items={colorItems}
                                                                                    bottomOffsetClassname="-top-24 mb-8"
                                                                                    leftOffsetClassName="-translate-x-60"
                                                                                    onChange={(
                                                                                        color:
                                                                                            | string
                                                                                            | null
                                                                                            | undefined,
                                                                                    ) => {
                                                                                        const newColor = color ?? '';
                                                                                        replace(index, {
                                                                                            ...annotation,
                                                                                            color: newColor,
                                                                                        });
                                                                                        setSelectedAnnotation({
                                                                                            ...annotation,
                                                                                            color: newColor,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    />
                                                                    <TextAreaField
                                                                        name="description"
                                                                        label="Description"
                                                                        subLabel="(optional)"
                                                                        maxLength={DESCRIPTION_CHARACTER_LIMIT}
                                                                        minRows={3}
                                                                        maxRows={6}
                                                                        placeholder="Add some notes about your rationale for the label you chose for this cluster..."
                                                                        value={annotation.description}
                                                                        inputClassName="!text-sm"
                                                                        className="no-margin"
                                                                        onChange={(
                                                                            e: ChangeEvent<HTMLTextAreaElement>,
                                                                        ) => {
                                                                            replace(index, {
                                                                                ...annotation,
                                                                                description: e.target.value,
                                                                            });
                                                                        }}
                                                                    />

                                                                    {/* Help identify cluster button */}
                                                                    {plotData && expanded === annotation.uuid && (
                                                                        <div className="mt-4">
                                                                            <Button
                                                                                variant="outlined"
                                                                                color="primary"
                                                                                size="small"
                                                                                startIcon={
                                                                                    <LightBulbIcon className="h-5 w-5" />
                                                                                }
                                                                                onClick={() =>
                                                                                    setIdentificationModalOpen(true)
                                                                                }
                                                                            >
                                                                                Help identify this cluster
                                                                            </Button>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    )}
                                </FieldArray>
                            </ScrollableSidebarContent>

                            <ScrollableSidebarFooter className="flex items-center justify-end">
                                {status && status.error && errorMessages.length === 0 && (
                                    <p className="mb-4 break-words rounded-lg bg-error px-2 py-2 text-error">
                                        {status.error}
                                    </p>
                                )}
                                <AggregateFormErrorAlert />

                                <CSSTransition timeout={300} classNames="fade" in={showSuccessMessage} unmountOnExit>
                                    <p className="mr-3 font-semibold text-success">Changes saved!</p>
                                </CSSTransition>
                                <AnalysisFormSubmitButton
                                    text="Save all changes"
                                    submittingText="Saving..."
                                    variant="contained"
                                    disabled={submitDisabled}
                                    fullWidth={false}
                                    onSubmit={() => setConfirmSubmitOpen(true)}
                                />
                            </ScrollableSidebarFooter>
                            <FormikListener values={values} callback={handleOnChange} />
                        </>
                    );
                }}
            </Formik>
            <ConfirmSaveAnnotationDialog
                open={confirmSubmitOpen}
                onConfirm={() => {
                    setConfirmSubmitOpen(false);
                    if (formRef.current) {
                        formRef.current.handleSubmit();
                    }
                }}
                onCancel={() => setConfirmSubmitOpen(false)}
            />
            <ClusterIdentificationModal
                open={identificationModalOpen}
                onClose={() => setIdentificationModalOpen(false)}
                annotation={selectedAnnotation}
                plotData={plotData}
                experiment={experiment}
            />
        </>
    );
};

export default AnnotationForm;
