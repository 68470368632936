import { OrganismID } from '@models/Organism';

/**
 * Represents a species/organism from CellKB API
 */
export type CellKBSpecies = {
    taxon_no: number;
    taxon_name: string;
    common_name: string;
    count: number;
};

/**
 * Maps Pluto organism IDs to CellKB taxon numbers
 */
export const mapPlutoOrganismToCellKB = (organismId?: string): number => {
    if (!organismId) return 10090; // Default to mouse if no organism is provided

    switch (organismId) {
        case OrganismID.human:
            return 9606; // Homo sapiens
        case OrganismID.mouse:
        case 'eastern_spiny_mouse':
            return 10090; // Mus musculus
        case OrganismID.rat:
            return 10116; // Rattus norvegicus
        case OrganismID.zebrafish:
            return 7955; // Danio rerio
        case OrganismID.fruitfly:
            return 7227; // Drosophila melanogaster
        default:
            return 10090; // Default to mouse for other organisms
    }
};

/**
 * Represents an option in the CellKB autocomplete dropdown
 */
export type CellKBOption = {
    name: string;
    ontology: string;
    count: number;
};

/**
 * Represents a predicted cell type from CellKB API
 */
export type CellKBPredictedCellType = {
    celltype: string;
    ontology: string;
    score: number;
    overlapping_canonical_markers?: string[];
};

/**
 * Represents an ontology entry (cell type, disease, or organ)
 */
export type CellKBOntology = {
    name: string;
    ontology: string;
};

/**
 * Represents a matching hit from the CellKB API
 */
export type CellKBMatchingHit = {
    cluster: string;
    cell_ontologies: CellKBOntology[];
    disease_ontologies: CellKBOntology[];
    organ_ontologies: CellKBOntology[];
    overlap_gene_count: number;
    rank_based_score: number;
    fdr: string;
};

/**
 * Represents the response from the CellKB API
 */
export type CellKBResponse = {
    status_code: string;
    broad_annotation: string;
    granular_annotation: string;
    predicted_celltypes: CellKBPredictedCellType[];
    top_matching_hits: CellKBMatchingHit[];
    invalid_genes: string[];
    reference_genelist_count: number;
};

/**
 * Represents a gene for the CellKB API request
 */
export type CellKBGene = {
    name: string;
    logfc: number;
    pval_adj: number;
};

/**
 * Represents the request parameters for the CellKB API
 */
export type CellKBRequestParams = {
    genes: CellKBGene[];
    inputSpecies: number;
    tissues?: string[];
    diseases?: string[];
    celltypes?: string[];
};

/**
 * Represents a cell type display from Google Gemini
 */
export type GeminiCellTypeDisplay = {
    tissue_type: string;
    confidence: string;
    reasons: string;
};
