import React, { useState } from 'react';
import {
    Button,
    CircularProgress,
    Tooltip,
    Chip,
    Divider,
    Box,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import { ChevronDownIcon } from '@heroicons/react/outline';
import CellKBAutocomplete from './CellKBAutocomplete';
import CellKBSpeciesSelector from './CellKBSpeciesSelector';
import GeneInfoPopover from '@components/GeneInfoPopover';
import Logger from '@util/Logger';
import { ResultData } from '@/src/models/PreprocessStep';
import Experiment from '@models/Experiment';
import { GenericCellData } from '@/src/models/ExperimentData';
import { OrganismID } from '@models/Organism';
import { CellKBOption, CellKBResponse, mapPlutoOrganismToCellKB } from '@models/CellKB';
import { HelpCircleIcon } from '@components/icons/custom/HelpCircleIcon';

const logger = Logger.make('CellKBSuggestions');

type CellKBSuggestionsProps = {
    clusterNumber: number;
    plotData: ResultData;
    experiment: Experiment;
    onClose?: () => void;
    onUseAsLabel?: (label: string) => void;
};

const CellKBSuggestions = ({ clusterNumber, plotData, experiment, onClose, onUseAsLabel }: CellKBSuggestionsProps) => {
    // Log the cluster number for debugging purposes
    logger.debug(`Rendering CellKB suggestions for cluster ${clusterNumber}`);
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState<CellKBResponse | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [advancedOptionsOpen, setAdvancedOptionsOpen] = useState(false);
    // State variables for expandable sections removed as we now use tooltips

    // Additional parameters for CellKB API
    const [selectedTissues, setSelectedTissues] = useState<CellKBOption[]>([]);
    const [selectedDiseases, setSelectedDiseases] = useState<CellKBOption[]>([]);
    const [selectedCellTypes, setSelectedCellTypes] = useState<CellKBOption[]>([]);
    const [inputSpecies, setInputSpecies] = useState<number>(mapPlutoOrganismToCellKB(experiment.organism?.shortname));

    // Helper function for retry logic with exponential backoff
    const withRetry = async <T,>(fn: () => Promise<T>, retries = 3, initialDelay = 1000): Promise<T> => {
        let lastError: Error | null = null;

        for (let attempt = 1; attempt <= retries; attempt++) {
            try {
                return await fn();
            } catch (error: any) {
                lastError = error;
                logger.warn(`Attempt ${attempt} failed:`, error.message);

                if (attempt === retries) {
                    throw error;
                }

                // Wait before retrying with exponential backoff
                const delay = initialDelay * Math.pow(2, attempt - 1);
                logger.info(`Retrying in ${delay}ms...`);
                await new Promise((resolve) => setTimeout(resolve, delay));
            }
        }

        // This should never happen, but TypeScript requires a return
        throw lastError || new Error('Unknown error during retry');
    };

    const fetchCellKBSuggestions = async () => {
        setLoading(true);
        setError(null);

        try {
            // Prepare the genes data from plotData
            const genes = (plotData.items as GenericCellData[])
                .filter(
                    (item: GenericCellData) => item.Log2_Fold_Change !== undefined && item.Log2_Fold_Change !== null,
                )
                .sort(
                    (a: GenericCellData, b: GenericCellData) =>
                        ((b?.Log2_Fold_Change as number) || 0) - ((a.Log2_Fold_Change as number) || 0),
                )
                .slice(0, 50) // Use top 50 marker genes for better cell type identification
                .map((item: GenericCellData) => ({
                    name: item.Gene_Symbol,
                    logfc: parseFloat((item.Log2_Fold_Change as number).toFixed(3)),
                    pval_adj: item.Adj_P_Value || 0,
                }));

            // Extract ontologies from selected options
            const tissuesArray = selectedTissues.map((t) => t.ontology);
            const diseasesArray = selectedDiseases.map((d) => d.ontology);
            const celltypesArray = selectedCellTypes.map((c) => c.ontology);

            // Define the API call function for retry
            const makeApiCall = async () => {
                const apiResponse = await fetch('/api/cellkb', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        genes,
                        inputSpecies,
                        tissues: tissuesArray,
                        diseases: diseasesArray,
                        celltypes: celltypesArray,
                    }),
                });

                if (!apiResponse.ok) {
                    throw new Error(`Error: ${apiResponse.status} ${apiResponse.statusText}`);
                }

                return await apiResponse.json();
            };

            // Call the API with retry logic
            const data = await withRetry(makeApiCall);

            // Process the response
            if (data.response && data.response.status_code === 'SUCCESS') {
                setResponse(data.response);
            } else {
                setError('No cell type suggestions found in the response');
            }
        } catch (error: any) {
            logger.error('Error fetching CellKB suggestions:', error);
            setError(`Failed to fetch cell type suggestions from CellKb: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="mt-4">
            <p className="mb-4">
                Cell type annotation suggestions generated by <a href="https://cellkb.com">CellKb</a>, a manually
                curated database of marker gene sets for rapid cell type identification.{' '}
                <a href="https://www.cellkb.com/citations" target="_blank" rel="noreferrer">
                    Citing CellKb
                </a>
            </p>

            {!response && !loading && !error && (
                <div className="space-y-4">
                    <div className="mb-4">
                        <Typography variant="subtitle2" className="mb-2">
                            Organism
                        </Typography>
                        <CellKBSpeciesSelector value={inputSpecies} onChange={setInputSpecies} />
                    </div>
                    <Typography variant="subtitle2" className="mb-2" style={{ fontSize: '0.85rem' }}>
                        Advanced options
                    </Typography>
                    <Accordion
                        expanded={advancedOptionsOpen}
                        onChange={() => setAdvancedOptionsOpen(!advancedOptionsOpen)}
                        className="shadow-none border rounded"
                        style={{ maxWidth: '500px' }}
                    >
                        <AccordionSummary
                            expandIcon={<ChevronDownIcon className="h-5 w-5" />}
                            aria-controls="advanced-options-content"
                            id="advanced-options-header"
                        >
                            <Typography>Advanced Options</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="space-y-4">
                                <Typography variant="body2" color="textSecondary" className="mb-2">
                                    Provide additional information to help CellKb refine its cell type suggestions.
                                </Typography>

                                <CellKBAutocomplete
                                    label="Tissues"
                                    placeholder="Select tissues"
                                    helperText="Specify known tissues to narrow down suggestions"
                                    endpoint="tissues"
                                    species={inputSpecies}
                                    dependencies={{
                                        diseases: selectedDiseases.map((d) => d.ontology),
                                        celltypes: selectedCellTypes.map((c) => c.ontology),
                                    }}
                                    value={selectedTissues}
                                    onChange={setSelectedTissues}
                                />

                                <CellKBAutocomplete
                                    label="Diseases"
                                    placeholder="Select diseases"
                                    helperText="Specify known diseases to refine suggestions"
                                    endpoint="diseases"
                                    species={inputSpecies}
                                    dependencies={{
                                        tissues: selectedTissues.map((t) => t.ontology),
                                        celltypes: selectedCellTypes.map((c) => c.ontology),
                                    }}
                                    value={selectedDiseases}
                                    onChange={setSelectedDiseases}
                                />

                                <CellKBAutocomplete
                                    label="Cell Types"
                                    placeholder="Select cell types"
                                    helperText="Specify suspected cell types to guide suggestions"
                                    endpoint="celltypes"
                                    species={inputSpecies}
                                    dependencies={{
                                        tissues: selectedTissues.map((t) => t.ontology),
                                        diseases: selectedDiseases.map((d) => d.ontology),
                                    }}
                                    value={selectedCellTypes}
                                    onChange={setSelectedCellTypes}
                                />
                            </div>
                        </AccordionDetails>
                    </Accordion>

                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={fetchCellKBSuggestions}
                        className="mb-2"
                    >
                        Load CellKb suggestions
                    </Button>
                </div>
            )}

            {loading && (
                <div className="flex items-center">
                    <CircularProgress size={20} className="mr-2" />
                    <span>Loading suggestions...</span>
                </div>
            )}

            {error && (
                <div className="text-red-500 p-3 border border-red-200 rounded bg-red-50">
                    <p className="mb-2">{error}</p>
                    {(selectedTissues.length > 0 || selectedDiseases.length > 0 || selectedCellTypes.length > 0) && (
                        <p className="text-sm">
                            <strong>Tip:</strong> Try searching without advanced options to get more results. Advanced
                            filters can sometimes be too restrictive.
                            <Button
                                size="small"
                                color="error"
                                variant="outlined"
                                className="ml-2"
                                onClick={async () => {
                                    setSelectedTissues([]);
                                    setSelectedDiseases([]);
                                    setSelectedCellTypes([]);
                                    setAdvancedOptionsOpen(false);

                                    // Wait for state to update before fetching
                                    setTimeout(() => {
                                        fetchCellKBSuggestions();
                                    }, 0);
                                }}
                            >
                                Clear Filters
                            </Button>
                        </p>
                    )}
                </div>
            )}

            {response && !loading && (
                <div className="space-y-4">
                    {/* Summary section */}
                    <div className="bg-gray-50 p-4 rounded border border-gray-200 mb-4">
                        <Typography variant="subtitle1" className="font-semibold">
                            Summary
                        </Typography>
                        <div className="flex flex-col space-y-1">
                            <div>
                                <span className="font-semibold">Broad annotation:</span> {response.broad_annotation}
                            </div>
                            <div>
                                <span className="font-semibold">Granular annotation:</span>{' '}
                                {response.granular_annotation}
                            </div>
                        </div>
                    </div>

                    <Divider />

                    {/* Main content - full width */}
                    <div className="flex flex-row">
                        {/* Full width column - scrollable cell types and matching hits */}
                        <div className="w-full max-h-[500px] overflow-y-auto pr-2">
                            <div className="mb-4">
                                <Typography
                                    variant="subtitle1"
                                    className="font-semibold sticky top-0 bg-white py-2 z-10"
                                >
                                    Top 3 predicted cell types
                                    <Tooltip
                                        title="The cell type prediction score is calculated for the top 3 predicted cell types from the ontology of the CellKb signatures having the top 20 highest match scores. The combined prediction scores of the top 3 cell types sum to 1. A higher prediction score for one cell type indicates a better match of the query gene list to that cell type compared to the other 2 of the top 3 predictions. These scores are relative within the current query and not directly comparable across different queries."
                                        arrow
                                        placement="right"
                                    >
                                        <span>
                                            <HelpCircleIcon height={16} width={16} className="inline-block ml-2" />
                                        </span>
                                    </Tooltip>
                                </Typography>
                                <div className="space-y-2">
                                    {response.predicted_celltypes.map((cellType, index) => (
                                        <div key={index} className="p-2 border rounded mb-2 ml-4">
                                            <div>
                                                <div className="flex items-center">
                                                    <span className="text-base font-semibold">{cellType.celltype}</span>
                                                    {onUseAsLabel && (
                                                        <Button
                                                            variant="text"
                                                            size="small"
                                                            color="primary"
                                                            onClick={() => onUseAsLabel(cellType.celltype)}
                                                            className="ml-1 min-w-0 p-1"
                                                        >
                                                            Use as label
                                                        </Button>
                                                    )}
                                                </div>
                                                <div className="text-xs  mb-1 mt-2">
                                                    <span className="font-semibold">Score:</span>{' '}
                                                    {cellType.score.toFixed(2)}
                                                </div>
                                                <div className="flex items-center">
                                                    <Chip
                                                        size="small"
                                                        label={cellType.ontology}
                                                        className="mr-2"
                                                        variant="outlined"
                                                    />
                                                </div>

                                                {/* Show overlapping markers for each cell type */}
                                                {cellType.overlapping_canonical_markers &&
                                                    cellType.overlapping_canonical_markers.length > 0 && (
                                                        <div className="pt-2">
                                                            <div className="text-xs mb-1">
                                                                <span className="font-semibold">
                                                                    Overlapping canonical markers:
                                                                </span>
                                                                {cellType.overlapping_canonical_markers &&
                                                                    cellType.overlapping_canonical_markers.length >
                                                                        0 && (
                                                                        <Chip
                                                                            size="small"
                                                                            label={`${cellType.overlapping_canonical_markers.length} genes`}
                                                                            className="ml-2"
                                                                            color="success"
                                                                            variant="outlined"
                                                                        />
                                                                    )}
                                                            </div>
                                                            <div className="flex flex-wrap gap-2">
                                                                {cellType.overlapping_canonical_markers.map(
                                                                    (gene, i) => (
                                                                        <GeneInfoPopover
                                                                            key={i}
                                                                            gene={gene}
                                                                            organism={
                                                                                experiment.organism
                                                                                    ?.shortname as OrganismID
                                                                            }
                                                                        >
                                                                            <Chip
                                                                                size="small"
                                                                                label={gene}
                                                                                color="primary"
                                                                                variant="outlined"
                                                                                className="cursor-pointer"
                                                                            />
                                                                        </GeneInfoPopover>
                                                                    ),
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="mt-6">
                                <Typography
                                    variant="subtitle1"
                                    className="font-semibold sticky top-0 bg-white py-2 z-10"
                                >
                                    Best matching cell type details
                                    <Tooltip
                                        title="The top 10 best matching cell types were scored based on the ranks of the genes in the query and the differences in the gene ranks between the query and the matching cell type signature. A higher match score indicates a
better match of the query gene list to the cell type signature. This is a positive value with no upper limit. Rank based scores are comparable across different queries and cell type signatures. Statistical significance of the overlap between query gene list and the matching cell type signature is calculated using Fisher's exact test with FDR adjustment for multiple testing. Lower FDR scores indicate a better overlap between the gene lists."
                                        arrow
                                        placement="right"
                                    >
                                        <span>
                                            <HelpCircleIcon height={16} width={16} className="inline-block ml-2" />
                                        </span>
                                    </Tooltip>
                                </Typography>
                                <div className="space-y-3">
                                    {response.top_matching_hits.slice(0, 5).map((hit, index) => (
                                        <Box key={index} className="p-2 border rounded ml-4">
                                            <div className="flex items-center ">
                                                <div className="text-base font-semibold">{hit.cluster}</div>
                                                {onUseAsLabel && hit.cell_ontologies.length > 0 && (
                                                    <Button
                                                        variant="text"
                                                        size="small"
                                                        color="primary"
                                                        onClick={() => onUseAsLabel(hit.cell_ontologies[0].name)}
                                                        className="ml-1 min-w-0 p-1"
                                                    >
                                                        Use as label
                                                    </Button>
                                                )}
                                            </div>
                                            <div className="text-xs">
                                                <div>
                                                    <span className="font-semibold">Score:</span>{' '}
                                                    {hit.rank_based_score.toFixed(2)}
                                                </div>
                                                <div>
                                                    <span className="font-semibold">FDR:</span> {hit.fdr}
                                                </div>
                                            </div>

                                            {/* Show overlapping genes for each matching hit */}
                                            <div className="pt-3">
                                                <div className="mb-2 text-xs">
                                                    <span className="font-semibold">
                                                        Overlapping canonical markers:
                                                    </span>{' '}
                                                    <Chip
                                                        size="small"
                                                        label={`${hit.overlap_gene_count} genes`}
                                                        color="success"
                                                        variant="outlined"
                                                    />
                                                </div>
                                                <div className="flex flex-wrap gap-2">
                                                    {/* Get the genes from plotData that match this hit */}
                                                    {(plotData.items as GenericCellData[])
                                                        .filter(
                                                            (item) =>
                                                                item.Log2_Fold_Change !== undefined &&
                                                                item.Log2_Fold_Change !== null,
                                                        )
                                                        .sort(
                                                            (a, b) =>
                                                                ((b?.Log2_Fold_Change as number) || 0) -
                                                                ((a.Log2_Fold_Change as number) || 0),
                                                        )
                                                        .slice(0, hit.overlap_gene_count)
                                                        .map((item, i) => {
                                                            if (typeof item.Gene_Symbol === 'string') {
                                                                return (
                                                                    <GeneInfoPopover
                                                                        key={i}
                                                                        gene={item.Gene_Symbol}
                                                                        organism={
                                                                            experiment.organism?.shortname as OrganismID
                                                                        }
                                                                    >
                                                                        <Chip
                                                                            size="small"
                                                                            label={item.Gene_Symbol}
                                                                            color="primary"
                                                                            variant="outlined"
                                                                            className="cursor-pointer"
                                                                        />
                                                                    </GeneInfoPopover>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                </div>
                                            </div>
                                            <div className="font-semibold mt-3 text-xs">Cell & organ ontologies</div>
                                            <div className="flex flex-wrap gap-1 pt-1">
                                                {hit.cell_ontologies.map((onto, i) => (
                                                    <div key={i} className="flex items-center mr-1 mb-1">
                                                        <Chip size="small" label={onto.name} variant="outlined" />
                                                    </div>
                                                ))}
                                                {hit.organ_ontologies.map((onto, i) => (
                                                    <div key={i} className="flex items-center mr-1 mb-1">
                                                        <Chip
                                                            size="small"
                                                            label={onto.name}
                                                            color="primary"
                                                            variant="outlined"
                                                        />
                                                    </div>
                                                ))}
                                                {hit.disease_ontologies && hit.disease_ontologies.length > 0 && (
                                                    <>
                                                        <div className="w-full mt-1">
                                                            <div className="font-semibold text-xs">
                                                                Disease associations
                                                            </div>
                                                        </div>
                                                        {hit.disease_ontologies.map((onto, i) => (
                                                            <div key={i} className="flex items-center mr-1 mb-1">
                                                                <Tooltip title={onto.ontology} arrow>
                                                                    <Chip
                                                                        size="small"
                                                                        label={onto.name}
                                                                        color="secondary"
                                                                        variant="outlined"
                                                                    />
                                                                </Tooltip>
                                                            </div>
                                                        ))}
                                                    </>
                                                )}
                                            </div>
                                        </Box>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* Right column removed as per user request */}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CellKBSuggestions;
