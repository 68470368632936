import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, CircularProgress, Chip, Typography, Box } from '@mui/material';
import Logger from '@util/Logger';
import { CellKBOption } from '@models/CellKB';

const logger = Logger.make('CellKBAutocomplete');

type CellKBAutocompleteProps = {
    label: string;
    placeholder?: string;
    helperText?: string;
    endpoint: string;
    species: number;
    dependencies?: {
        tissues?: string[];
        diseases?: string[];
        celltypes?: string[];
    };
    value: CellKBOption[];
    onChange: (newValue: CellKBOption[]) => void;
    disabled?: boolean;
};

const CellKBAutocomplete = ({
    label,
    placeholder,
    helperText,
    endpoint,
    species,
    dependencies = {},
    value,
    onChange,
    disabled = false,
}: CellKBAutocompleteProps) => {
    const [options, setOptions] = useState<CellKBOption[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    // Helper function for retry logic with exponential backoff
    const withRetry = async <T,>(fn: () => Promise<T>, retries = 3, initialDelay = 1000): Promise<T> => {
        let lastError: Error | null = null;

        for (let attempt = 1; attempt <= retries; attempt++) {
            try {
                return await fn();
            } catch (error: any) {
                lastError = error;
                logger.warn(`Attempt ${attempt} failed:`, error.message);

                if (attempt === retries) {
                    throw error;
                }

                // Wait before retrying with exponential backoff
                const delay = initialDelay * Math.pow(2, attempt - 1);
                logger.info(`Retrying in ${delay}ms...`);
                await new Promise((resolve) => setTimeout(resolve, delay));
            }
        }

        // This should never happen, but TypeScript requires a return
        throw lastError || new Error('Unknown error during retry');
    };

    useEffect(() => {
        const fetchOptions = async () => {
            if (!species) return;

            setLoading(true);
            setError(null);

            try {
                // Define the API call function for retry
                const makeApiCall = async () => {
                    const response = await fetch(`/api/cellkb/${endpoint}`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            species,
                            ...dependencies,
                        }),
                    });

                    if (!response.ok) {
                        throw new Error(`Error: ${response.status} ${response.statusText}`);
                    }

                    return await response.json();
                };

                // Call the API with retry logic
                const data = await withRetry(makeApiCall);

                if (data.response && data.response.status_code === 'SUCCESS') {
                    setOptions(data.response.result || []);
                } else {
                    setError('Failed to fetch options');
                }
            } catch (error: any) {
                logger.error(`Error fetching ${endpoint}:`, error);
                setError(`Failed to fetch ${endpoint}: ${error.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchOptions();
    }, [species, endpoint, JSON.stringify(dependencies)]);

    return (
        <Autocomplete
            multiple
            options={options}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.ontology === value.ontology}
            value={value}
            onChange={(_, newValue) => onChange(newValue)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    placeholder={placeholder}
                    helperText={error || helperText}
                    error={!!error}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip label={option.name} {...getTagProps({ index })} key={option.ontology} />
                ))
            }
            renderOption={(props, option) => (
                <li {...props}>
                    <Box>
                        <Typography variant="body1">{option.name}</Typography>
                        <Typography variant="caption" color="text.secondary">
                            {option.ontology} ({option.count} datasets)
                        </Typography>
                    </Box>
                </li>
            )}
            disabled={disabled || loading}
            loading={loading}
            loadingText="Loading options..."
            noOptionsText="No options available"
            filterSelectedOptions
        />
    );
};

export default CellKBAutocomplete;
