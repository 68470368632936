import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, Tabs, Tab, Box, Typography, IconButton, TextField } from '@mui/material';
import PlutoDialogTitle from '@components/PlutoDialogTitle';
import { PencilIcon } from '@heroicons/react/solid';
import { ResultData } from '@/src/models/PreprocessStep';
import Experiment from '@models/Experiment';
import { OrganismID } from '@models/Organism';
import { Annotation } from '@models/Annotation';
import CellKBSuggestions from './CellKBSuggestions';
import { fetchCellTypes } from './AnnotationForm';
import Logger from '@util/Logger';
import { AnnotationValues } from './AnnotationFormTypes';
import GeneInfoPopover from '@components/GeneInfoPopover';
import Button from '@components/Button';
import { GeminiCellTypeDisplay } from '@models/CellKB';
import DialogCloseButton from '@components/DialogCloseButton';
import Link from 'next/link';
import { HelpCircleIcon } from '@components/icons/custom/HelpCircleIcon';

const logger = Logger.make('ClusterIdentificationModal');

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`cluster-identification-tabpanel-${index}`}
            aria-labelledby={`cluster-identification-tab-${index}`}
            {...other}
            className="mt-4"
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return { id: `cluster-identification-tab-${index}`, 'aria-controls': `cluster-identification-tabpanel-${index}` };
}

interface ClusterIdentificationModalProps {
    open: boolean;
    onClose: () => void;
    annotation: Annotation | AnnotationValues | null;
    plotData: ResultData | null;
    experiment: Experiment;
    onUpdateAnnotation?: (annotationId: string, newName: string) => void;
}

const ClusterIdentificationModal = ({
    open,
    onClose,
    annotation,
    plotData,
    experiment,
    onUpdateAnnotation,
}: ClusterIdentificationModalProps) => {
    const [tabValue, setTabValue] = useState(0);
    const [geminiSuggestions, setGeminiSuggestions] = useState<GeminiCellTypeDisplay[] | null>(null);
    const [geminiLoading, setGeminiLoading] = useState(false);
    const [geminiError, setGeminiError] = useState<string | null>(null);
    const [clusterLabel, setClusterLabel] = useState<string>('');
    const [isEditingLabel, setIsEditingLabel] = useState<boolean>(false);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        // Reset state when modal opens with a new annotation
        if (open && annotation) {
            setGeminiSuggestions(null);
            setGeminiLoading(false);
            setGeminiError(null);
            setTabValue(0);
            setClusterLabel(annotation.display_name || '');
            setIsEditingLabel(false);

            // Automatically load Gemini suggestions
            loadGeminiSuggestions();
        }
    }, [open, annotation?.uuid]);

    const handleLabelChange = (newLabel: string) => {
        setClusterLabel(newLabel);
    };

    const handleLabelSave = () => {
        if (onUpdateAnnotation && annotation && clusterLabel !== annotation.display_name) {
            onUpdateAnnotation(annotation.uuid, clusterLabel);
        }
        setIsEditingLabel(false);
    };

    const handleUseAsLabel = (label: string) => {
        setClusterLabel(label);
        if (onUpdateAnnotation && annotation) {
            onUpdateAnnotation(annotation.uuid, label);
        }
    };

    const loadGeminiSuggestions = async () => {
        if (!annotation || !plotData) return;

        setGeminiLoading(true);
        setGeminiError(null);

        try {
            const cellTypesResponse = await fetchCellTypes(annotation.number, plotData, experiment);
            const parsedResponse = cellTypesResponse.response?.[0]?.tissue_types || [];

            if (parsedResponse && Array.isArray(parsedResponse)) {
                setGeminiSuggestions(parsedResponse);
            } else {
                setGeminiError('No suggestions available for this cluster');
            }
        } catch (error) {
            logger.error('Error fetching AI annotations:', error);
            setGeminiError('Failed to fetch cell type suggestions');
        } finally {
            setGeminiLoading(false);
        }
    };

    if (!annotation) return null;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xl"
            fullWidth
            aria-labelledby="cluster-identification-dialog-title"
            PaperProps={{ style: { minHeight: '80vh', maxHeight: '90vh' } }}
        >
            <PlutoDialogTitle>Explore annotations suggestions for Cluster {annotation.number}</PlutoDialogTitle>
            <DialogCloseButton onClose={onClose} />

            <DialogContent dividers>
                <div className="flex flex-row space-x-6">
                    {/* Left Column - Suggestions */}
                    <div className="flex-1">
                        <div className="mt-2 mb-2">
                            {isEditingLabel ? (
                                <div className="flex items-center">
                                    <TextField
                                        value={clusterLabel}
                                        onChange={(e) => handleLabelChange(e.target.value)}
                                        size="small"
                                        label="Cluster Label"
                                        variant="outlined"
                                        className="mr-2"
                                        autoFocus
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                handleLabelSave();
                                            }
                                        }}
                                    />
                                    <Button variant="contained" color="primary" size="small" onClick={handleLabelSave}>
                                        Save
                                    </Button>
                                </div>
                            ) : (
                                <div className="flex items-center">
                                    <Typography variant="subtitle1" className="mr-2">
                                        Current label <strong>{clusterLabel || `Cluster ${annotation.number}`}</strong>
                                    </Typography>
                                    <IconButton size="small" onClick={() => setIsEditingLabel(true)} color="primary">
                                        <PencilIcon className="h-4 w-4" />
                                    </IconButton>
                                </div>
                            )}
                        </div>

                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} aria-label="cluster identification tabs">
                                <Tab label="CellKB Suggestions" {...a11yProps(0)} />
                                <Tab label="Google Gemini Suggestions" {...a11yProps(1)} />
                            </Tabs>
                        </Box>

                        <TabPanel value={tabValue} index={0}>
                            {plotData && (
                                <CellKBSuggestions
                                    clusterNumber={annotation.number}
                                    plotData={plotData}
                                    experiment={experiment}
                                    onUseAsLabel={handleUseAsLabel}
                                />
                            )}
                        </TabPanel>

                        <TabPanel value={tabValue} index={1}>
                            <div className="mb-4">
                                <Typography variant="body2" color="textSecondary" className="mb-4">
                                    Google Gemini analyzes the marker genes for this cluster and suggests possible cell
                                    types based on gene expression patterns.
                                </Typography>

                                {geminiLoading && <div className="text-center py-4">Loading suggestions...</div>}

                                {geminiError && (
                                    <div className="text-red-500 py-2">
                                        {geminiError}
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            onClick={loadGeminiSuggestions}
                                            className="ml-4"
                                        >
                                            Retry
                                        </Button>
                                    </div>
                                )}

                                {geminiSuggestions && (
                                    <div className="space-y-4">
                                        {geminiSuggestions.map((cellTypeObj, index) => (
                                            <div key={index} className="p-4 border rounded-lg">
                                                <div className="flex justify-between items-start">
                                                    <div>
                                                        <div className="text-lg font-semibold mb-1">
                                                            {cellTypeObj.tissue_type}
                                                            <Button
                                                                variant="outlined"
                                                                size="small"
                                                                color="primary"
                                                                onClick={() =>
                                                                    handleUseAsLabel(cellTypeObj.tissue_type)
                                                                }
                                                                className="ml-2"
                                                            >
                                                                Use as label
                                                            </Button>{' '}
                                                        </div>
                                                        <div>Confidence: {cellTypeObj.confidence}</div>
                                                        <div className="text-sm">
                                                            <span className="font-medium">Reasoning: </span>
                                                            {cellTypeObj.reasons}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </TabPanel>
                    </div>

                    {/* Right Column - Marker Genes */}
                    {plotData && (
                        <div className="w-2/5 border-l pl-6">
                            <div className="flex justify-end mt-2">
                                <Link href="https://help.pluto.bio/en/articles/cellkb-annotations" target="_blank">
                                    <HelpCircleIcon height={16} width={16} className="inline-block" /> Annotations help
                                </Link>
                            </div>

                            <h2 className="mb-2 mt-6 text-base">Top marker genes for cluster {annotation.number}</h2>
                            <div className="overflow-auto max-h-[calc(80vh-200px)] border rounded">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50 sticky top-0">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Gene
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Log2 FC
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Adj P-Value
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {(plotData.items as any[])
                                            .filter(
                                                (item) =>
                                                    item.Log2_Fold_Change !== undefined &&
                                                    item.Log2_Fold_Change !== null,
                                            )
                                            .sort(
                                                (a, b) =>
                                                    ((b?.Log2_Fold_Change as number) || 0) -
                                                    ((a.Log2_Fold_Change as number) || 0),
                                            )
                                            .slice(0, 150)
                                            .map((item, index) => (
                                                <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                                    <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                                        <GeneInfoPopover
                                                            gene={item.Gene_Symbol}
                                                            organism={experiment.organism?.shortname as OrganismID}
                                                        >
                                                            <span className="cursor-pointer text-blue-600 hover:underline">
                                                                {item.Gene_Symbol}
                                                            </span>
                                                        </GeneInfoPopover>
                                                    </td>
                                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                        {(item.Log2_Fold_Change as number).toFixed(3)}
                                                    </td>
                                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                        {item.Adj_P_Value ? item.Adj_P_Value.toExponential(2) : 'N/A'}
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default ClusterIdentificationModal;
